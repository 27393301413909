import cls from './index.module.css';

const links = [
  {
    name: 'Privacy',
    href: 'https://www.zenlayer.com/privacy-policy/',
  },
  {
    name: 'Policy',
    href: 'https://www.zenlayer.com/cookie-policy/',
  },
  {
    name: 'Terms',
    href: 'https://www.zenlayer.com/terms-of-use/',
  },
];

function Footer() {
  return (
    <footer className={cls.footer}>
      <nav>
        {links.map(({ name, href }) => (
          <a key={name} href={href} target="_blank" rel="noreferrer">
            {name}
          </a>
        ))}
        <span>Copyright © {new Date().getFullYear()} Zenlayer</span>
      </nav>
    </footer>
  );
}

export default Footer;

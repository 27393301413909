import { useEffect, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import classnames from '@/utils/classnames';
import logoImg from '@/assets/images/logo.svg';
import cls from './index.module.css';

interface Props {
  nav: {
    name: string;
    path: string;
  }[];
}

const isScrolled = () => {
  return document.documentElement.scrollTop >= 72;
};

function Header({ nav }: Props) {
  const location = useLocation();
  const [showShadow, toggleShadow] = useState(isScrolled());
  useEffect(() => {
    const onScroll = () => {
      toggleShadow(isScrolled());
    };
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);
  return (
    <header className={classnames(cls.header, { [cls.shadow]: showShadow })}>
      <div className={cls.logo}>
        <img src={logoImg} alt="" />
        <span>API DOCS</span>
      </div>
      <nav className={cls.nav}>
        {nav.map(({ name, path }) => (
          <Link
            key={name}
            to={path}
            className={classnames({
              [cls.active]: !!matchPath(location.pathname, {
                path,
                exact: true,
              }),
            })}
          >
            {name}
          </Link>
        ))}
      </nav>
    </header>
  );
}

export default Header;

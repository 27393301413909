import { useEffect } from 'react';
import { RedocStandalone } from 'redoc';
import './index.css';

function Doc({ file }: { file: string }) {
  useEffect(() => {
    // 去除文字"Documentation Powered by ReDoc"
    const replaceNode = (ele: Element) => {
      if (ele.innerHTML === 'Documentation Powered by ReDoc') {
        ele.parentNode?.removeChild(ele);
      }
      if (ele.children?.length > 0) {
        for (let i = 0; i < ele.children.length; i++) {
          const element = ele.children[i];
          replaceNode(element);
        }
      }
    };
    const observer = new MutationObserver((mutations) => {
      if (mutations?.length) {
        for (let idx = 0; idx < mutations.length; idx++) {
          const mutation = mutations[idx];
          if (mutation.addedNodes?.length) {
            for (let i = 0; i < mutation.addedNodes.length; i++) {
              const element = mutation.addedNodes[i];
              replaceNode(element as Element);
            }
          }
        }
      }
    });
    observer.observe(document.body, {
      childList: true,
      attributes: true,
      subtree: true,
    });
    return observer.disconnect;
  }, []);
  return (
    <div style={{ flexGrow: 1 }}>
      <RedocStandalone
        specUrl={file}
        options={{
          hideLoading: true,
          scrollYOffset: 72,
          theme: {
            colors: {},
            typography: {
              fontFamily: 'Open Sans, Arial, sans-serif',
              headings: {
                fontFamily: 'Open Sans, Arial, sans-serif',
              },
              code: {
                fontFamily: 'Open Sans, Arial, sans-serif',
              },
              links: {
                color: '#24b7ff',
              },
            },
            sidebar: {
              backgroundColor: '#fff',
              textColor: '#616e82',
              activeTextColor: '#24b7ff',
              groupItems: {
                textTransform: 'uppercase',
              },
            },
          },
        }}
      />
    </div>
  );
}

export default Doc;

import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import {Footer, Header} from '@/components';
import Doc from '@/pages/doc';
import introYml from '@/spec/INTRO.yml';
import bmcYml from '@/spec/BMC.yml';
import sdnYml from '@/spec/SDN.yml';
import zgaYml from '@/spec/ZGA.yml';
import vmYml from '@/spec/VM.yml';
import billYml from '@/spec/Bill.yml';
import cdnYml from '@/spec/CDN.yml';
import partnerYml from '@/spec/PARTNER.yml';


const DOC_ROUTES = [
    {
        name: 'Introduction',
        path: '/api/doc/intro',
        file: introYml,
    },
    {
        name: 'Bare Metal Cloud',
        path: '/api/doc/bmc',
        file: bmcYml,
    },
    {
        name: 'Cloud Networking',
        path: '/api/doc/sdn',
        file: sdnYml,
    },
    {
        name: 'Global Accelerator',
        path: '/api/doc/zga',
        file: zgaYml,
    },
    {
        name: 'Virtual Machine',
        path: '/api/doc/vm',
        file: vmYml,
    },
    {
        name: 'Content Delivery',
        path: '/api/doc/cdn',
        file: cdnYml,
    },
    {
        name: 'Account Management',
        path: '/api/doc/bill',
        file: billYml,
    },
    {
        name: 'Partner',
        path: '/api/doc/partner',
        file: partnerYml,
    },
];

function App() {
    return (
        <BrowserRouter>
            <Header nav={DOC_ROUTES}/>
            <main>
                <Switch>
                    {DOC_ROUTES.map(({name, path, file}) => {
                        return (
                            <Route
                                key={name}
                                path={path}
                                component={() => <Doc file={file}/>}
                                exact
                            />
                        );
                    })}
                    <Redirect to={DOC_ROUTES[0].path}/>
                </Switch>
                <Footer/>
            </main>
        </BrowserRouter>
    );
}

export default App;
